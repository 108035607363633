<template>
  <div class="" style="width: 100%;height: 100%;">
    <el-dialog title="" :visible.sync="dialogVisible_detail" custom-class="cus_dialog cus_dialogFullHeight" width="90%"
      :close-on-click-modal="false" @closed="onClose">
      <div class="myDialogBox flexBetween">
        <div class="flexCloumnSingleCenter" style="height:100%;width: 40%;">
          <div class="boxShadow flexCloumnSingleCenter" style="height: 60%;width: calc( 100% - 0px );">
            <div class="titleBar flexStart">
              <div class="div flexStart">{{ dialogTitle }}</div>
            </div>
            <div class="flex_1" style="width:100%;overflow-y: auto;margin-bottom: 10px;">
              <el-form class="" :model="EditItem" ref="addzc" label-position="left" label-width="90px"
                style="width:calc( 100% - 10px );">

                <el-form-item label="申请人:">{{EditItem.username}}
                </el-form-item>
                <el-form-item label="所在部门:">{{EditItem.dept_name}}</el-form-item>
                <el-form-item label="申请时间:">{{EditItem.cdate}}</el-form-item>
                <el-form-item label="申领单号:">{{EditItem.sl_num}}</el-form-item>
                <el-form-item label="申领原因:" prop="sl_remark">
                  <el-input type="textarea" :rows="5" :value="EditItem.sl_remark||'-'" size="mini" readonly></el-input>
                </el-form-item>
                <el-form-item label="图片:">
                  <div class="flexStart">
                    <div v-if="EditItem.files&&EditItem.files.length>0" class="updimage"
                      v-for="(f, idx) in EditItem.files" :style="{ backgroundImage: 'url(' + f.src + ')' }"
                      style="position: relative">
                      <img :src="f.src" @click="handlePictureCardPreview(f.src)"
                        style="opacity: 0;width: 100%;height: 100%;cursor:pointer;" />
                    </div>

                    <div v-if="!EditItem.files||EditItem.files.length==0" class="">-</div>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>


          <div class="shadow flexCloumn mt10" style="height:  calc( 40% - 10px);width: 100%;">
            <div class="titleBar flexStart">
              <div class="div flexStart">流程状态</div>
            </div>
            <processview v-if="EditItem.id" ref="processview" :process_code="process_code" table_name="zichan_sl"
              :form_id="EditItem.id" class="flex_1" style="overflow-y: auto;height: calc( 100% - 50px );">
            </processview>
          </div>
        </div>

        <div class="shadow flexCloumnSingleCenter" style="height: 100%;width: calc( 60% - 10px);">
          <el-table ref="mySGtable" :data="EditItem.hc_list" height="100%" stripe border
            style="width:fit-content;width:100%;">
            <el-table-column type="index" label="序号" width="55" align="center" />
            <el-table-column prop="title" label="耗材名称" width="200" :show-overflow-tooltip="true">

            </el-table-column>
            <el-table-column prop="pinpai" label="品牌" width="100" />
            <el-table-column prop="xinghao" label="型号" width="100" />
            <!-- <el-table-column prop="typename1" label="分类"  width="100"/> -->
            <el-table-column prop="storename" label="所在仓库" min-width="200" />

            <el-table-column prop="store_num" width="90" label="剩余库存" />
            <el-table-column prop="jine" width="150" :show-overflow-tooltip="true" align="center">
              <template slot="header">
                <span style="color: #F56C6C">申领数量</span>
              </template>
              <template slot-scope="scope">
                <span style="color: #F56C6C">{{scope.row.slNum}}{{ scope.row.jldw }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="EditItem.ck_status==1" prop="ckNum" width="150" :show-overflow-tooltip="true"
              align="center">
              <template slot="header">
                <span style="color: #F56C6C">出库数量</span>
              </template>
              <template slot-scope="scope">
                <span class="cgInput">{{scope.row.ckNum}}{{ scope.row.jldw }}</span>
              </template>
            </el-table-column>


          </el-table>
        </div>
      </div>
      <div slot="footer" class="flexEnd">
        <span v-if="EditItem.ck_remark&&EditItem.sp_status>0"
          style="white-space: nowrap;">出库意见：</span>

        <el-input v-if="EditItem.ck_remark&&EditItem.sp_status>0" v-model="EditItem.ck_remark" size="small" readonly
          style="margin: 0 10px"></el-input>

        <el-button type="primary" @click="onClose" plain size="small">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import processview from "../../../com/processviewmini.vue"

export default {
  components: {
    processview,
  },
  data() {
    return {
      //--审批流程用----
      process_code: "hcsl",
      process_id:0,
      needChoose: false,//是否需要选择下级
      choosedUser: [],//已选择的用户
      //--审批流程用----
      disabled: false,
      files:[],
      arr_json:[],
      is_ck:false,
      EditItem:{}
    }
  },
  props:['dialogVisible_detail','dialogTitle','EditItem1'],
  mounted() {
    console.log(126126126,this.EditItem)
    this.handleEditItem()
  },
  methods: {
    //--审批流程用----
    isChooseuser(e) {//设置是否需要选人
      this.needChoose = e
    },
    chooseUser(e) {//设置选择的人
      this.choosedUser = e
    },
    getProcessID() {
      this.$http.post("/api/form_process", {
        process_code: this.process_code
      }).then(res => {
        this.process_id = res.data.id

      })
    },
    //--审批流程用----
    handleEditItem(){
      let _this = this
      let flag = false
      let ckList = [],arr = []
      this.$http.post('/api/zc_hc_sl_form',{id:this.EditItem1.id}).then(res => {
        if(res.data){
          let resData = res.data
          if (resData.hc_list) {
            resData.hc_list && resData.hc_list.map(h => {
              h.ckd = false
              h.can_ck = false
              if (!h.ck_status || h.ck_status == 0) {
                console.log(158158,h.id)
                _this.check_ck(h.id).then(b => {
                  if (b) {
                    flag = true
                  }
                  h.can_ck = b
                })
              }else if(h.ck_status==1){
                ckList.push(h)
              }
            })
            /*if(ckList&&ckList.length>0){
              arr = [ckList[0]]
              ckList.map((a,idx)=>{
                let namestr = arr.map((n) => n.ck_username).join(',')
                let namearr = namestr.split(',')
                let index = namearr.indexOf(a.ck_username)
                if(index>-1){
                  if(idx>0) arr[index].title+=`,${a.title}`
                }else {
                  arr.push(a)
                }
              })
              console.log(118,arr)
            }*/
          }
          if (resData.files){
            resData.files = JSON.parse(resData.files)
          }
            if (resData.qs_json) {
            resData.qs_json = JSON.parse(resData.qs_json)
          }
          if(resData.cdate){
            resData.cdate = resData.cdate.substr(0, 16)
          }
          setTimeout(() => {
            _this.EditItem = resData
            _this.is_ck = flag
            _this.ckList = arr
          }, 500)
        }

      })


    },
    check_ck(id) {
      return new Promise(resolve => {
        this.$http.post("/api/is_ck_admin", {
          id: id,
        }).then(res => {
          resolve(res.data.boole)
        })
      })

    },
    handlePictureCardPreview(src){
      console.log(this.$parent)
      this.$$parent(this,'handlePictureCardPreview',src)
    },
    onClose(){
      this.$$parent(this,'dialogVisible_detail',false)
    }
  }
}
</script>
<style>
.addDialog .el-textarea .el-textarea__inner{
  color: #303133!important;
}
</style>


